

.accordion {
    &-item{
        margin-bottom: 12px;
        border: none;
        background-color: rgba($lightColor, 0.52);
        @include borderRadius(8px !important);
    }
    &-button {
        background-color: rgba($lightColor, 0.82);
        @include borderRadius(8px);
        &:not(.collapsed) {
            background-color: rgba($lightColor, 0.82);
            @include box-shadow(0,0,0,0, rgba($darkColor, 0));
            color: lighten($darkColor, 26.67);
        }
        &:focus {
            border-color: none;
            @include box-shadow(0,0,0,0, rgba($darkColor, 0));
        }
    }

    &-pointer {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        background-color: $lightColor;
        display: flex;
        align-items: center;
        justify-content: center;
        border: darken(desaturate(adjust-hue($main-color, -9), 9.23), 10.39) 1px solid;
        @include borderRadius(50%);
        @include transition(0.5s ease all);
        svg {
            width: 20px;
            height: auto;
            fill: darken(desaturate(adjust-hue($main-color, -9), 9.23), 10.39);
        }
    }

    &-parent {
        &-item{
            margin-bottom: 12px;
            border: none;
           // border-bottom: lighten($darkColor, 67.84) 1px solid;
           // background-color: rgba($lightColor, 0);
            @include borderRadius(0px !important);
        }    
        &-button {
            background-color: rgba($lightColor, 0);
            font-size: 26px;
            padding: 15px 55px 15px 0;
            @include borderRadius(0);
            @include for-size(md-up) {
                font-size: 36px;
                padding: 20px 62px 20px 0;
            }
            &:not(.collapsed) {
                background-color: rgba($lightColor, 0);
                @include box-shadow(0,0,0,0, rgba($darkColor, 0));
                color: lighten($darkColor, 26.67);
                .accordion-pointer {
                    background-color: darken(desaturate(adjust-hue($main-color, -9), 9.23), 10.39);
                    @include transform(rotate(45deg));
                    svg {
                        fill: $lightColor;
                    }
                }
            }
            &::after {
                background-image: none !important;
            }
        }

        &-body {
            padding: 1.2rem 0;
        }
    }

    &-footer {
        @include breakpoint(mx-mob-large) {
            position: relative;
            margin-bottom: 8px;
            input[type='checkbox'] {
                width: 100%;
                height: 100%;
                position: absolute;
                opacity: 0;
                margin: 0;
                left: 0;
                z-index: 12;
                &:checked {
                    & ~ .nav-footer {
                        display: block;
                    }
                    & ~ h4 {
                        @include borderRadius(5px 5px 0px 0px);
                        &::after {
                            @include transform(rotate(180deg));
                        }
                    }
                }
            }
            .nav-footer {
                padding: 27px;
                display: none;
                position: relative;
                z-index: 12;
                background-color: darken(desaturate($main-color, 14.44), 15.29);
                @include borderRadius(0px 0px 5px 5px);
            }

            h4 {
                background-color:  rgba(darken(desaturate($main-color, 8.06), 26.08), 0.35);
                padding: 17px 18px;
                margin-bottom: 0;
                position: relative;
                font-size: 16px;
                @include borderRadius(5px);
                &::after {
                    content: "";
                    position: absolute;
                    top: 18px;
                    right: 16px;
                    width: 12px;
                    height: 12px;
                    background-image: url(../../img/down-arrow.svg);
                    background-size: 100%;
                    filter: invert(1);
                    @include transition(0.3 ease all);
                }
            }
        }
    }
}