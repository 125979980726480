
.nav {
    
    &-min {
        li {
            a {
                color: $lightColor;
                font-size: 15px;
                padding: 10px 15px;
                display: block;
                @include for-size(lg-up) {
                    color: $darkColor;
                    padding: 10px 6px;
                    font-size: 14px;
                }
                @include for-size(xl-up) {
                    padding: 10px 15px;
                    font-size: 15px;
                }
                &:hover {
                    color: $darkColor;
                    @include for-size(lg-up) {
                        color: $main-color;
                    }
                }
            }
        }
    }


    &-footer {
        li {
            margin-bottom: 12px;
            a {
                font-size: 16px;
                font-weight: 300;
                color: $lightColor;
                line-height: 1.2;
            }
        }
    }

    &-social {
        li {
            a {
                width: 32px;
                height: 32px;
                background: darken(desaturate($main-color, 24.21), 9.02);
                @include borderRadius(50%);
                @include box-shadow(0,2px,1px,1px, rgba($darkColor, 0.2));
                &:hover {
                    color: $lightColor;
                    @include box-shadow(0,2px,5px,1px, rgba($lightColor, 0.8));
                }
            }
        }
    }

    &-breadcrumbs {
        padding: 10px 20px;
        background-color: rgba($lightColor, 0.75);
        position: relative;    
        margin-left: -12px;
        &::after {
            content: "";
            width: 12px;
            height: 100%;
            position: absolute;
            top: 0;
            right: -12px;
            background-color: rgba($lightColor, 0.75);
            clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
            -webkit-clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
            @include for-size(md-up) {
                width: 18px;
                left: 100%;
                right: inherit;
            }
        }
        @include for-size(md-up) {
            position: absolute;
            bottom: 0;
            left: 1rem;
            margin: 0;
        }
        li {
            &:not(:last-child) {
                &::after {
                    content: "|";
                    display: inline-block;
                    padding: 0 10px;
                }
            }
            a {
                 @include text-shadow(0,0,1px, rgba($darkColor, 0.8));
            }
        }
    }
}


