
.navbar {
    @include transition(0.2s ease all);
    background-color: rgba($lightColor, 0.95);
    &-brand {
        width: 122px;
        @include for-size(md-up) {
            width: 178px;
        }
        @include for-size(xl-up) {
            width: 212px;
        }
    }
    &-fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        padding: 1rem 0;
        @include for-size(md-up) {
            padding: 1.3rem 0;
        }
    }

    &-toggler {
        width: 27px;
        padding: 0;
        height: 16px;
        border: none;
        @include for-size(md-up) {
            width: 30px;
        }
        &-icon {
            display: block;
            position: relative;
            width: 100%;            
            height: 2px;
            background-color: lighten(saturate(adjust-hue($darkColor, 45), 8.89), 17.65);

            &::before, &::after {
                content: "";
                height: 2px;
                position: absolute;
                left: 0;
                background-color: lighten(saturate(adjust-hue($darkColor, 45), 8.89), 17.65);
            }
            &::before {
                width: 100%;
                margin-bottom: 5px;
                bottom: 100%;
            }
            &::after {
                width: 66.6666%;
                margin-top: 5px;
                top: 100%;
            }
        }

        &:focus {
            @include box-shadow(0,0,0,0, rgba($darkColor,0));
        }
    }

    &-bg {
        background-color: transparent;
        top: -92px;
    }

    &-btn {
        //margin-left: auto !important;
        margin-right: 10px;
        padding: 7px 16px !important;
        @include for-size(lg-up) {
            margin-right: 0;
            margin-left: 15px !important;
            padding: 7px 22px !important;
        }
    }

    // &-lang {
    //     li {
    //         margin-left: 5px;
    //         width: 20px;
    //         height: 15px;            
    //         a {
    //             display: block;
    //             position: relative;
    //             &::before {
    //                 content: "";
    //                 position: absolute;
    //                 width: 100%;
    //                 height: 100%;
    //                 //background-color: rgba($darkColor, 0);
    //                 top: 0;
    //                 left: 0;
    //             }
    //             &::after {
    //                 content: "";
    //                 opacity: 0;
    //                 position: absolute;
    //                 top: 1px;
    //                 left: 42%;
    //                 width: 5px;
    //                 height: 10px;
    //                 z-index: 12;
    //                 border-bottom: $lightColor 2px solid;
    //                 border-right: $lightColor 2px solid;
    //                 @include transform(rotate(45deg));
    //             }
    //             img {
    //                 width: 100%;
    //                 height: 100%;
    //                 display: block;
    //             }
    //         }
    //         &.active {
    //             a {
    //                 &::before {
    //                     background-color: rgba($darkColor, 0.3);
    //                 }
    //                 &::after {
    //                     opacity: 1;
    //                 }
    //             }
    //         }
    //     }
    // }
}

.offcanvas {
    background-color: rgba(darken(desaturate(adjust-hue($main-color, -1), 20.91), 31.37), 0.5);
    border-right: none;
    z-index: 99999;
    backdrop-filter: blur(10px);
    @include breakpoint(mx-mob-medium) {
        width: 220px;
    }

    &-backdrop {
        background-color: rgba($lightColor, 0.1);
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        
        &.show {
            opacity: 1;
        }
    }

    &-header {
        .btn-close {
            filter: invert(1);
            opacity: 1;
        }
    }

    &-title {
        font-family: $font-sub;
        font-size: 25px;
        line-height: 1;
        color: $lightColor;
        letter-spacing: 1.2;
    }
}