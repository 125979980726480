
.carousel {
    &-indicators {
        &-banner {
            margin: 0;
            bottom: -11px;
            justify-content: flex-start;
            button {
                width: 15px !important;
                height: 15px !important;
                border: none !important;
            }
        }
        &-module {
            justify-content: center;
            bottom: -29px;
            button {
                width: 10px !important;
                height: 10px !important;
                border: none !important;
                background-color: $main-color !important;
            }
        }
    }
}

.slick {
    &-dots {
        bottom: -53px;
        li {
            margin: 0 5px;
            button {
                width: 15px;
                height: 15px;
                background-color: darken($lightColor, 8.63);
                border: lighten(saturate($main-color, 8.91), 28.24) 1px solid;
                &::before {
                    display: none;
                }
            }
            &.slick-active {
                button {
                    background-color: transparent;
                }
            }
        }
    }
    &-dotted {
        &.slick-slider {
            margin-bottom: 52px !important;
        }
    }
}