





h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 400;
    line-height: 1;
    color: lighten($darkColor, 8.63);
    //font-family: $font-sub;
    span {
        color: $main-color;
    }
}



h1, .h1 {
    font-size: 30px;
    margin-bottom: 15px;  
    line-height: 1;
    @include for-size(md-up) {
        font-size: 46px;
        margin-bottom: 18px;
    }    
}

h2, .h2 {
    font-size: 25px;
    margin-bottom: 15px;
    line-height: 1;
    @include for-size(md-up) {
        font-size: 35px;
        margin-bottom: 16px;
    }
}

h3, .h3 {
    font-size: 23px;
    margin-bottom: 15px;
    @include for-size(md-up) {
        font-size: 28px;
    }
}

h4, .h4 {
    font-size: 21px;
    margin-bottom: 15px;
    @include for-size(md-up) {
        font-size: 26px;
        margin-bottom: 18px;
    }
}

h5, .h5 {
    font-size: 18px;
    margin-bottom: 15px;
    @include for-size(md-up) {
        font-size: 24px;
        margin-bottom: 18px;
    }
}

h6, .h6 {
    font-size: 16px;
    margin-bottom: 15px;
    @include for-size(md-up) {
        font-size: 22px;
        margin-bottom: 18px;
    }
}


p {	
    line-height: 1.7;
    font-weight: 400;
    margin-bottom: 1.2rem;
    font-size: 14px;
    @include for-size(md-up) {
        line-height: 1.8;   
    }
    img {
        border: $main-color 1px solid;
    }
}

b, strong {
    font-weight: 700;
}