


.form {    
    label {
        font-size: 13px;
        font-weight: 700;
    }
    &-group {
        label {
            color: lighten($darkColor, 23.92);
            font-weight: 400;
            padding-left: 15px;
            margin-bottom: 8px;
            letter-spacing: 0.8px;
        }       
    }
    &-control {
        padding: 10px 15px;
        font-size: 14px;
        background-color: $lightColor;
        @include borderRadius(5px);
        color: $darkColor;
        border-color: transparent;
        font-weight: 600;
        @include box-shadow(0,3px,5px,1px, rgba($darkColor, 0));
        &:focus {
            border-color: $main-color;
            background-color: $lightColor;
            color: $darkColor;
            @include box-shadow(0,0,8px,1px, rgba($main-color, 0.8));
        }
        @include placeholder {
            color: $darkColor;
            font-weight: 500 !important;
            opacity: 1;
        }
        &-large {
            &-font {
                @include for-size(md-up) {
                    font-size: 22px;
                    padding: 8px 18px;
                }
                @include for-size(lg-up) {
                    font-size: 30px;
                    padding: 0px 18px;
                }
            }
        }
    }
    input{
        &[type="date"], &[type="time"] {
            &::-webkit-calendar-picker-indicator {
                filter: invert(0);
            }
        }        
    }
    textarea {
        &.form-control {
            height: 108px;
            resize: none;
        }
    }
    select {
        &.form-control {
            background-image: url(../../img/down-arrow.svg);
            background-position: 95% center;
            background-repeat: no-repeat;
            background-size: 12px;
            &-large {
                &-font {
                    option {
                        font-size: 14px;
                    }
                }
            }
            option {
                background-color: $lightColor;
            }
        }
    }

    &-row {
        margin: 0 -8px;
    }
    &-col {
        padding: 0 8px;
        .form-row {
            margin: 0 -7px;
            .form-col {
                padding: 0 7px;
            }
        }
    }

    &-newslatter {
        position: relative;
        .form-control {
            padding: 15px;
            background-color: rgba(darken(desaturate($main-color, 8.06), 26.08), 0.35);
            border-color: transparent;
            color: $lightColor;
            padding-right: 50px;
            @include borderRadius(5px);
            @include placeholder {
                color: $lightColor;
                font-weight: 500 !important;
                opacity: 1;
            }
            &:focus {
                border-color: lighten(saturate($main-color, 8.91), 28.24);
            }
        }
        &-btn {
            background-color: transparent;
            border: none;
            position: absolute;
            top: 14px;
            width: 35px;
            right: 8px;
            svg {
                width: 100%;
                height: auto;
                fill: $lightColor;
            }
        }
    }
}

