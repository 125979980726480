
.hdn {
    &-bg {
        position: relative;
        &::after {
            content: "";
            width: 100%;
            height: 6px;
            border-top-width: 1px;
            border-bottom-width: 1px;
            border-style: solid;
            position: absolute;
            border-color: transparent;
            top: 50%;
            left: 0;
            @include transform(translateY(-50%));
        }
        &-gry {
            &::after {
                border-top-color: lighten($darkColor, 94.90);
                border-bottom-color: lighten($darkColor, 94.90);
            }
        }
        &-main {
            &::after {
                border-top-color: lighten(saturate($main-color, 20.85), 27.65);
                border-bottom-color: lighten(saturate($main-color, 20.85), 27.65);
            }
        }

        .position-relative {
            z-index: 12;
        }
    }
}