
$primary-fonts: 'Montserrat', sans-serif;
$font-sub: 'Poppins', sans-serif;



body {
    font-family: $primary-fonts;
    line-height: 1.5;
    font-size: 14px;
    background-color: $lightColor;
    font-weight: 400;
    color: lighten(saturate(adjust-hue($darkColor, 210), 8.89), 17.65);      
    padding-top: 4.3rem;
    @include for-size(md-up) {
        padding-top: 5.3rem; 
    }
}




a {
    color: $main-color;
    text-decoration: none;
	&:hover {
        opacity: 1;		
		text-decoration: none;
        color: darken(saturate(adjust-hue($main-color, -9), 20.85), 45.88);
	}
}

a, .btn, button {
	@include transition(all 0.2s ease);
	&:hover, &:focus, &:active {
		text-decoration: none;
		outline: 0;	
	}
}

ul, ol {
	@include reset-list;    
}

svg, img {
	max-width: 100%;
}

.text-underline {
    text-decoration: underline;
}


.container {
	max-width: 1240px;
    position: relative;    
}



.cms {
    ul, ol {
        padding-left: 15px;
        margin-bottom: 1.2rem;
        li {
            margin-bottom: 5px;
            position: relative;
            color:  lighten(saturate(adjust-hue($darkColor, 224), 18.52), 15.88);
            line-height: 1.8;
            font-weight: 400;
            font-size: 14px;
        }
    }
    ul, ol {
        li {
            padding-left:  15px;
            &::before {
                content: "";
                position: absolute;
                left: 0;
            }           
        }
        &.text-center {
            padding-left: 0;
            @include flexbox;
            justify-content: center;
            li {
                margin: 0 15px 12px;
            }
        }
    }
    ul{
        li {
            padding-left:  15px;            
            &::before {
                width: 4px;
                height: 4px;
                @include borderRadius(50%);
                background-color: lighten(desaturate(adjust-hue($main-color, -1), 17.91), 6.67);
                top: 10px;
            }
        }
    }
    ol {
        counter-reset: x;
        li {
            counter-increment: X;
            padding-left: 21px;
            &::before {                
                content: counter(X)'.';
                font-weight: 500;
                font-size: 14px;
                top: 5px;
                line-height: 1;
                color: lighten(desaturate(adjust-hue($main-color, -1), 17.91), 6.67);
                
            }
        }
    }
    a {
        &:not(.btn) {
            color: $main-color;
            text-decoration: underline;
            &:hover {
                color: darken(saturate(adjust-hue($main-color, -9), 20.85), 45.88);
            }
        }
    }

    b, strong {
        font-weight: 600;
    }
    strong {        
        color: $main-color;
    }
}

.slick-dotted.slick-slider {
    margin-bottom: 22px !important;
}


.slider-dots-box {
    width: 100%;

}



.slick-slide {
    height: auto !important;
}





.notFound {   
    padding: 5rem 0; 
    @include for-size(md-up) {
        height: 100vh;
        @include col;
        justify-content: center;
        padding: 0;
    }
    h1, h3 {
        color: $darkColor;
        span {
            color: $darkColor;
        }
    }
    h3 {
        line-height: 1.4;
    }
    h1 {
        line-height: 1.1;
        font-size: 36px;
        span {
            display: block;
            line-height: 1;
            font-size: 80px;
            margin-bottom: 12px;
            @include for-size(md-up) {
                font-size: 100px;  
            }
            @include for-size(lg-up) {
                font-size: 120px;            
            }
        }
        @include for-size(md-up) {
            font-size: 50px;  
        }
        @include for-size(lg-up) {
            font-size: 62px;            
        }
    }
    p {
        color: $darkColor;
        font-size: 16px;
        a {
            font-weight: 700;
            text-decoration: underline;
            color: $main-color;
            &:hover {
                color: $darkColor;
            }
        }
    }

}


.cookies {
    position: fixed;
    width: 100%;
    padding: 16px 15px;
    bottom: 0;
    right: 0;
    z-index: 999;
    background-color: rgba($darkColor, 0.99);    
    text-align: center;
    @include for-size(lg-up) {
        @include flexbox;
        align-items: flex-start;
        text-align: left;
        padding: 25px;
    }
    span {
        color: $lightColor;
        letter-spacing: 1.2px;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        max-width: 350px;
        display: block;
        @include for-size(lg-up) {
            margin-right: 18px;
            width: 75%;
            margin-left: 0;
            max-width: inherit;
        }
    }
    div {
        margin-left: auto;
        margin-top: 15px;
        cursor: pointer;
        @include for-size(lg-up) {
            margin-left: auto;
            margin-top: 0;
        }
        a {
            font-weight: 700;
            display: inline-block;
            padding: 5px 15px;
            background-color: $lightColor;
            color: $darkColor !important;
            @include borderRadius(5px);
        }
    }
}


#message {
    padding: 12px 15px;
    font-size: 14px;
    margin-bottom: 10px;
    @include borderRadius(5px);
    &.error {
        background-color: lighten(darken(adjust-hue($main-color, 124), 15.10), 35%);
        color: darken($main-color, 35%);
    }
    &.success {
        background-color: lighten(darken(adjust-hue($main-color, 124), 15.10), 60%);
        color: darken(darken(adjust-hue($main-color, 124), 15.10), 35%);
    }
}

.section-intro {
    position: relative;
    padding: 65px 0;
    margin-top: 35px;
    &::after {
        content: "";
        height: 32px;
        width: 1px;
        position: absolute;
        background-color: $darkColor;
        top: 0;
        left: 50%;
    }
}