//font-waight
.f {
    &800 {
        font-weight: 800 !important;
    }
    &700 {
        font-weight: 700 !important;
    }
    &600 {
        font-weight: 600 !important;
    }
    &500 {
        font-weight: 500 !important;
    }
    &400 {
        font-weight: 400 !important;
    }
    &300 {
        font-weight: 300 !important;
    }
    &200 {
        font-weight: 200 !important;
    }
    &xxl {
        font-size: 18px !important;
        &-lg {
            @include for-size(lg-up) {
                font-size: 20px !important;
            }
        }
    }
    &xl {
        font-size: 17px !important;
        &-lg {
            @include for-size(lg-up) {
                font-size: 18px !important;
            }
        }
    }
    &lg {
        font-size: 15px !important;
        &-lg {
            @include for-size(lg-up) {
                font-size: 17px !important;
            }
        }
    }
    &md {
        font-size: 14px !important;
        &-lg {
            @include for-size(lg-up) {
                font-size: 16px !important;
            }
        }
    }
    &dark{
        color: $darkColor;
    }
    &main{
        color: $main-color;
    }
    &white{
        color: $lightColor;
    }
}

.bg{
    &-white {
        background-color: $lightColor;
    }
}

.font {
    &-light {
        color: $lightColor;        
    }
}

.width {
    &-full {
        width: 100% !important;
        max-width: 100% !important;
    }
    &-xl {
        &-auto {
            @include for-size(xl-up) {
                width: auto!important;
            }
        }
    }
    &-lg {
        &-auto {
            @include for-size(lg-up) {
                width: auto!important;
            }
        }
    }
    &-md {
        &-auto {
            @include for-size(md-up) {
                width: auto!important;
            }
        }
    }
    &-sm {
        &-auto {
            @include for-size(sm-up) {
                width: auto !important;
            }
        }
    }
}


.u-text-shadow {
    @include text-shadow(0, 2px, 10px, rgba($darkColor, 0.3));
}

.text {    
    &-transform {
        &-none {
            text-transform: none;
        }
        &-uppercase {
            text-transform: uppercase;
        } 
    }
       
    &-left {
        text-align: left !important;
    }
    &-right {
        text-align: right !important;
    }
    &-center {
        text-align: center !important;
    }
    &-xl {
        @include for-size(xl-up) {
            &-left {
                text-align: left !important;
            }
            &-right {
                text-align: right !important;
            }
            &-center {
                text-align: center !important;
            }
        }
    }
    &-lg {
        @include for-size(lg-up) {
            &-left {
                text-align: left !important;
            }
            &-right {
                text-align: right !important;
            }
            &-center {
                text-align: center !important;
            }
        }
    }
    &-md {
        @include for-size(md-up) {
            &-left {
                text-align: left !important;
            }
            &-right {
                text-align: right !important;
            }
            &-center {
                text-align: center !important;
            }
        }
    }
    &-sm {
        @include for-size(sm-up) {
            &-left {
                text-align: left !important;
            }
            &-right {
                text-align: right !important;
            }
            &-center {
                text-align: center !important;
            }
        }
    }
}


//padding

.pl {
    &-0 {
        padding-left: 0 !important;
    }
    &-15 {
        padding-left: 15px !important;
    }
    &-1 {
        padding-left: 1rem !important;
    }    
    &-2 {
        padding-left: 2rem !important;
    }    
    &-3 {
        padding-left: 3rem !important;
    }    
    &-4 {
        padding-left: 4rem !important;
    }    
    &-5 {
        padding-left: 5rem !important;
    }
    &-sm {
        @include for-size(sm-up) {
            &-0 {
                padding-left: 0 !important;
            }
            &-1 {
                padding-left: 1rem !important;
            }
            &-2 {
                padding-left: 2rem !important;
            }
            &-3 {
                padding-left: 3rem !important;
            }
            &-4 {
                padding-left: 4rem !important;
            }
            &-5 {
                padding-left: 5rem !important;
            }
        }
    }
    &-md {
        @include for-size(md-up) {
            &-0 {
                padding-left: 0 !important;
            }
            &-1 {
                padding-left: 1rem !important;
            }
            &-2 {
                padding-left: 2rem !important;
            }
            &-3 {
                padding-left: 3rem !important;
            }
            &-4 {
                padding-left: 4rem !important;
            }
            &-5 {
                padding-left: 5rem !important;
            }
        }
    }
    &-lg {
        @include for-size(lg-up) {
            &-0 {
                padding-left: 0 !important;
            }
            &-1 {
                padding-left: 1rem !important;
            }
            &-2 {
                padding-left: 2rem !important;
            }
            &-3 {
                padding-left: 3rem !important;
            }
            &-4 {
                padding-left: 4rem !important;
            }
            &-5 {
                padding-left: 5rem !important;
            }
        }
    }
    &-xl {
        @include for-size(lg-up) {
            &-0 {
                padding-left: 0 !important;
            }
            &-1 {
                padding-left: 1rem !important;
            }
            &-2 {
                padding-left: 2rem !important;
            }
            &-3 {
                padding-left: 3rem !important;
            }
            &-4 {
                padding-left: 4rem !important;
            }
            &-5 {
                padding-left: 5rem !important;
            }
        }
    }
}

.pr {
    &-0 {
        padding-right: 0 !important;
    }    
    &-15 {
        padding-right: 15px !important;
    }    
    &-1 {
        padding-right: 1rem !important;
    }    
    &-2 {
        padding-right: 2rem !important;
    }    
    &-3 {
        padding-right: 3rem !important;
    }    
    &-4 {
        padding-right: 4rem !important;
    }    
    &-5 {
        padding-right: 5rem !important;
    }
    &-sm {
        @include for-size(sm-up) {
            &-0 {
                padding-right: 0 !important;
            }
            &-1 {
                padding-right: 1rem !important;
            }
            &-2 {
                padding-right: 2rem !important;
            }
            &-3 {
                padding-right: 3rem !important;
            }
            &-4 {
                padding-right: 4rem !important;
            }
            &-5 {
                padding-right: 5rem !important;
            }
        }
    }
    &-md {
        @include for-size(md-up) {
            &-0 {
                padding-right: 0 !important;
            }
            &-1 {
                padding-right: 1rem !important;
            }
            &-2 {
                padding-right: 2rem !important;
            }
            &-3 {
                padding-right: 3rem !important;
            }
            &-4 {
                padding-right: 4rem !important;
            }
            &-5 {
                padding-right: 5rem !important;
            }
        }
    }
    &-lg {
        @include for-size(lg-up) {
            &-0 {
                padding-right: 0 !important;
            }
            &-1 {
                padding-right: 1rem !important;
            }
            &-2 {
                padding-right: 2rem !important;
            }
            &-3 {
                padding-right: 3rem !important;
            }
            &-4 {
                padding-right: 4rem !important;
            }
            &-5 {
                padding-right: 5rem !important;
            }
        }
    }
    &-xl {
        @include for-size(xl-up) {
            &-0 {
                padding-right: 0 !important;
            }
            &-1 {
                padding-right: 1rem !important;
            }
            &-2 {
                padding-right: 2rem !important;
            }
            &-3 {
                padding-right: 3rem !important;
            }
            &-4 {
                padding-right: 4rem !important;
            }
            &-5 {
                padding-right: 5rem !important;
            }
        }
    }
}


//margin

.ml {
    &-auto {
        margin-left: auto;
    }
    &-sm {
        &-auto {
            @include for-size(sm-up) {
                margin-left: auto;
            }
        }
    }
    &-md {
        &-auto {
            @include for-size(md-up) {
                margin-left: auto;
            }
        }
    }
    &-lg {
        &-auto {
            @include for-size(lg-up) {
                margin-left: auto;
            }
        }
    }
    &-xl {
        &-auto {
            @include for-size(xl-up) {
                margin-left: auto;
            }
        }
    }
}

.mr {
    &-auto {
        margin-right: auto;
    }
    &-sm {
        &-auto {
            @include for-size(sm-up) {
                margin-right: auto;
            }
        }
    }
    &-md {
        &-auto {
            @include for-size(md-up) {
                margin-right: auto;
            }
        }
    }
    &-lg {
        &-auto {
            @include for-size(lg-up) {
                margin-right: auto;
            }
        }
    }
    &-xl {
        &-auto {
            @include for-size(xl-up) {
                margin-right: auto;
            }
        }
    }
}


.colm {
    &-2 {
        column-count: 2;
    }
    &-3 {
        column-count: 3;
    }
    &-4 {
        column-count: 4;
    }
    &-5 {
        column-count: 5;
    }
    &-sm {
        &-2 {
            @include for-size(sm-up) {
                column-count: 2;
            }
        }
        &-3 {
            @include for-size(sm-up) {
                column-count: 3;
            }
        }
        &-4 {
            @include for-size(sm-up) {
                column-count: 4;
            }
        }
        &-5 {
            @include for-size(sm-up) {
                column-count: 5;
            }
        }

        &-gap{
            &-0 {
                @include for-size(sm-up) {
                    column-gap: 0rem;
                }
            }
            &-1 {
                @include for-size(sm-up) {
                    column-gap: 1rem
                }
            }
            &-2 {
                @include for-size(sm-up) {
                    column-gap: 2rem;
                }
            }
            &-3 {
                @include for-size(sm-up) {
                    column-gap: 3rem;
                }
            }
            &-4 {
                @include for-size(sm-up) {
                    column-gap: 4rem;
                }
            }
            &-5 {
                @include for-size(sm-up) {
                    column-gap: 5rem;
                }
            }
        }
    }
    &-md {
        &-2 {
            @include for-size(md-up) {
                column-count: 2;
            }
        }
        &-3 {
            @include for-size(md-up) {
                column-count: 3;
            }
        }
        &-4 {
            @include for-size(md-up) {
                column-count: 4;
            }
        }
        &-5 {
            @include for-size(md-up) {
                column-count: 5;
            }
        }

        &-gap{
            &-0 {
                @include for-size(md-up) {
                    column-gap: 0rem;
                }
            }
            &-1 {
                @include for-size(md-up) {
                    column-gap: 1rem;
                }
            }
            &-2 {
                @include for-size(md-up) {
                    column-gap: 2rem;
                }
            }
            &-3 {
                @include for-size(md-up) {
                    column-gap: 3rem;
                }
            }
            &-4 {
                @include for-size(md-up) {
                    column-gap: 4rem;
                }
            }
            &-5 {
                @include for-size(md-up) {
                    column-gap: 5rem;
                }
            }
        }
    }
    &-lg {
        &-2 {
            @include for-size(lg-up) {
                column-count: 2;
            }
        }
        &-3 {
            @include for-size(lg-up) {
                column-count: 3;
            }
        }
        &-4 {
            @include for-size(lg-up) {
                column-count: 4;
            }
        }
        &-5 {
            @include for-size(lg-up) {
                column-count: 5;
            }
        }

        &-gap{
            &-0 {
                @include for-size(lg-up) {
                    column-gap: 0rem;
                }
            }
            &-1 {
                @include for-size(lg-up) {
                    column-gap: 1rem;
                }
            }
            &-2 {
                @include for-size(lg-up) {
                    column-gap: 2rem;
                }
            }
            &-3 {
                @include for-size(lg-up) {
                    column-gap: 3rem;
                }
            }
            &-4 {
                @include for-size(lg-up) {
                    column-gap: 4rem;
                }
            }
            &-5 {
                @include for-size(lg-up) {
                    column-gap: 5rem;
                }
            }
        }
    }
    &-xl {
        &-2 {
            @include for-size(xl-up) {
                column-count: 2;
            }
        }
        &-3 {
            @include for-size(xl-up) {
                column-count: 3;
            }
        }
        &-4 {
            @include for-size(xl-up) {
                column-count: 4;
            }
        }
        &-5 {
            @include for-size(xl-up) {
                column-count: 5;
            }
        }

        &-gap{
            &-0 {
                @include for-size(xl-up) {
                    column-gap: 0rem;
                }
            }
            &-1 {
                @include for-size(xl-up) {
                    column-gap: 1rem;
                }
            }
            &-2 {
                @include for-size(xl-up) {
                    column-gap: 2rem;
                }
            }
            &-3 {
                @include for-size(xl-up) {
                    column-gap: 3rem;
                }
            }
            &-4 {
                @include for-size(xl-up) {
                    column-gap: 4rem;
                }
            }
            &-5 {
                @include for-size(xl-up) {
                    column-gap: 5rem;
                }
            }
        }
    }
}


.d-xxl {
    @include for-size(xxl-up) {
        &-none {
            display: none !important;
        }
        &-block {
            display: block !important;
        }
    }
}