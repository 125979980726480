
.about {
    &-section {
        &-heading {
            background-image: url(../../img/introHdnBg.png);
            background-repeat: repeat;
            
        }

        .slick-slide {
            margin: 0 8px;
        }
        .slick-slide, .slick-slide > div {
            height: 100%;
        }
        .slick-track {
            padding-bottom: 8px;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &-cols {
        &--slider {
            margin: 0 -12px;
            @include for-size(xl-up) {
                margin: 0 -6px;
            }
        }       
    }

    &-col {
        padding-bottom: 60px;
        
        position: relative;
        @include box-shadow(0,4px,5px,1px,rgba($darkColor, 0.05));
        height: 100%;
        @include for-size(xl-up) {
            width: calc(25% - 12px);
            margin: 0 6px;
            height: auto;
        }
        &-img {
            img {
                width: 100%;
                height: auto;
            }
        }

        &-text {
            padding: 0 14px;
        }

        h3 {
            font-size: 16px;
        }

        .btn {
            position: absolute;
            bottom: 18px;
            width: calc(100% - 28px);
            left: 50%;
            @include transform(translateX(-50%));
        }
    }

    &-us {
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        &::before {
            content: "";
            width: 100%;
            height: 74px;
            background-image: url(../../img/background-shep.png);
            position: absolute;
            bottom: 0;
            left: 0;
            background-size: 100% 100%;
            @include for-size(sm-up) {
                height: 94px;
            }
            @include for-size(md-up) {
                width: 450px;
                height: 44px;
            }
            @include for-size(lg-up) {
                width: 620px;
                height: 74px;
            }
            @include for-size(xl-up) {
                width: 860px;
                height: 134px;
            }
        }

        img {
            width: 100%;
            height: auto;
        }

        &-intro {
            h3 {
                font-weight: 500;
                padding: 12px 20px;
                background-color: rgba($lightColor, 0.74);
                color: lighten($darkColor, 26.67);
                margin: 0 0 2px;
                font-size: 20px;
                letter-spacing: 0.5px;
            }

            &-text {
                background-color: rgba($lightColor, 0.74);
                padding: 12px 20px;
                margin-bottom: 15px;
                p {
                    font-weight: 300;
                }
            }
        }
    }

    &-services {
        &--slider {
            @include for-size(lg-up) {
                @include flexbox;
            }

            .slick-slide {
                margin: 0 8px
            }
        }

        &-img {
            @include flexbox;
            align-items: center;
            justify-content: center;
            padding: 16px;
            min-height: 126px;
            border: lighten(saturate(adjust-hue($main-color, -6), 20.85), 32.75) 1px solid;
            @include transition(0.3s ease all);
            img {
                width: auto;
                height: auto;
            }
        }

        &-col {
            @include for-size(lg-up) {
                width: calc(25% - 16px);
                margin: 0 8px 22px;
            }
            h6 {
                font-size: 12px;
                @include transition(0.3s ease all);
                margin-bottom: 0;
                margin-top: 10px;
            }
            &:hover {
                .about-services-img {
                    background-color: lighten(saturate(adjust-hue($main-color, -7), 20.85), 36.86);
                    border-color: lighten(saturate(adjust-hue($main-color, -7), 20.85), 36.86);
                }
                h6 {
                    color: darken(desaturate(adjust-hue($main-color, -14), 22.98), 15.69);
                }
            }
        }
    }

    &-team {
        img {
            width: 100%;
            height: auto;
        }

        &-member {
            &-item {
                padding: 10px 15px 35px;
                background-color: darken($lightColor, 3.14);
                @include borderRadius(5px);
                @include transition(ease all 0.5s);
                height: 100%;
                @include for-size(md-up) {
                    margin: 0 8px 12px;
                    height: auto;
                }
                @include for-size(lg-up) {
                    width: calc(20% - 16px);
                }
                @include for-size(xl-up) {
                    width: calc(16.6666% - 16px);
                }
                
                &:hover {
                    @include transform(scale(1.03));
                    background-color: lighten(saturate(adjust-hue($main-color, -8), 6.40), 8.82);
                    .about-team-member-img {
                        border-color: lighten(saturate(adjust-hue($main-color, -9), 17.22), 30.59);
                        img {
                            @include for-size(lg-up) {
                                filter: grayscale(0);
                            }
                        }
                    }
                    .about-team-member-dec .fmain {
                        color: $lightColor;
                    }
                }

                &-slider {
                    .slick-track {
                        padding: 12px 0;
                        @include flexbox;
                    }
                    .slick-slide {
                        margin: 0 8px;
                    }
                    .slick-slide, .slick-slide > div {
                        height: 100%;
                    }
                }
            }
            &-img {
                width: 154px;
                height: 154px;
                overflow: hidden;
                margin: 0 auto 15px;
                border: darken($lightColor, 10.59) 5px solid;
                @include transition(ease all 0.8s);
                @include borderRadius(50%);
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;                    
                    @include borderRadius(50%);
                    @include for-size(lg-up) {
                        filter: grayscale(1);
                    }
                }
            }
            &-dec {                
                .fmain {
                    @include transition(ease all 0.3s);
                }
            }
        }
    }

    &-client {
        &--slide {
            .slick-track {
                display: flex;
                flex-wrap: wrap;
                padding: 15px 0;
            }
            .slick-slide {
                margin: 0 8px;
            }
            .slick-slide, .slick-slide > div {
                height: 100%;
            }
        }
        &-col {            
            background-color: $lightColor;
            border: lighten(saturate($main-color, 8.91), 28.24) 1px solid;
            @include borderRadius(5px);
            padding: 25px 8px;
            height: 100%;
            display: flex !important;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            @include box-shadow(0,0,22px,1px, rgba($darkColor, 0.08));
            @include for-size(lg-up) {
                width: calc(20% - 16px);
                margin: 0 8px 10px;
                height: auto;
            }
        }
    }

    &-page {
        &-context {

        }
        &-row {
            @include box-shadow(0,2px,3px,1px, rgba($darkColor, 0.08));
            &:nth-of-type(2n+2) {
                .about-page-img {
                    @include for-size(md-up) {
                        order: 2;
                    }
                }
                .cms {
                    @include for-size(md-up) {
                        order: 1;
                        text-align: right;
                    }
                    @include for-size(lg-up) {
                        padding-right: 45px;
                    }
                }
            }

            .cms {
                text-align: center;
                padding-top: 15px;
                @include for-size(md-up) {
                    text-align: left;
                }
                @include for-size(lg-up) {
                    padding-left: 45px;
                }
            }
        }

        &-img {
            img {
                width: 100%;
                height: auto;
            }
        }

        
    }

    &-purpose {
        &-img {
            img {
                width: 100%;
                height: auto;
            }
        }
        &-text {
            @include for-size(lg-up) {
                padding: 25px;
                @include box-shadow(2px,3px,10px,1px, rgba($darkColor, 0.08));
                background-color: $lightColor;
                position: absolute;
                @include transform(translateY(-50%));
            }
            @include for-size(xl-up) {
                padding: 35px;
            }
        }
    }
    
}