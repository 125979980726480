
.btn {
    padding: 15px 32px;
    font-size: 15px;
    font-weight: 700;
    @include borderRadius(8px);

    &-sm {
        padding: 7px 32px;
        font-size: 14px;
    }

    &-full {
        width: 100%;
        display: block;
        text-align: center;
    }

    &-bg {
        &-main {
            background-color: $main-color;
            color: $lightColor;
            &:hover {
                background-color: lighten(saturate(adjust-hue($main-color, -9), 10.85), 25.69);
                color: $darkColor;
            }
            &-light {
                background-color: lighten(saturate(adjust-hue($main-color, -9), 10.85), 25.69);
                color: lighten($darkColor, 18.82);                
            }
            &-lighter {
                background-color: lighten(saturate(adjust-hue($main-color, -6), 20.85), 36.67);
                color: lighten($darkColor, 42.75);
            }
            &-light, &-lighter {
                &:hover {
                    background-color: $main-color;
                    color: $lightColor;
                }
            }
        }

        &-white {
            background-color: $lightColor;
            border-color: darken($lightColor, 12.94);
            color: lighten($darkColor, 47.84);
            &:hover {
                background-color: lighten(saturate(adjust-hue($main-color, -6), 20.85), 36.67);
                color: lighten($darkColor, 42.75);;
                border-color: lighten(saturate(adjust-hue($main-color, -6), 20.85), 36.67);
            }
        }
    }
}