//Base colors



$main-color: #4285e9;

$lightColor: #fff;
$darkColor: #000;






