

.servicesModule {
    &-section {
        background-size: cover;
        position: relative;
        &::before {
            content: "";
            width: 100%;
            height: 74px;
            background-image: url(../../img/background-shep.png);
            position: absolute;
            bottom: 0;
            right: 0;
            background-size: 100% 100%;
            @include transform(rotateY(180deg));
            @include for-size(sm-up) {
                height: 94px;
            }
            @include for-size(md-up) {
                width: 450px;
                height: 44px;
            }
            @include for-size(lg-up) {
                width: 620px;
                height: 74px;
            }
            @include for-size(xl-up) {
                width: 860px;
                height: 134px;
            }
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}