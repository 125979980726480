

.dropdown {
    &-navbar {
        &-lang {
            margin-left: 15px;
            img {
                width: 22px;
                height: 15px;
                margin: 0 5px 0 0;
            }
            .btn {
                background-color: transparent;
                padding: 0;
                border: none;
                color: $darkColor;
                text-transform: uppercase;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                &:focus {
                    background-color: transparent;
                    color: $darkColor;
                }
            }
            .dropdown-item {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
        }
    }
}