

.technology {
    &-intro {
        img {
            width: 100%;
            height: auto;
        }
    }

    &-solutions {
        background-color: darken($lightColor, 3.92);
    }

    
}

.accordion-parent-body {
    img {
        width: 100%;
        height: auto;
    }
}