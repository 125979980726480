

.footer {
    &-min {
        position: relative;
        background-color: $main-color;
        padding: 35px 0 0;
        @include for-size(lg-up) {
            padding: 45px 0 0;
        }
        @include for-size(xl-up) {
            padding: 92px 0 0;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: var(--background);
            background-size: cover;
            opacity: 0.1;
        }
    }

    &-col {
        width: 100%;
        @include for-size(md-up) {
            width: 50%;
        }
        @include for-size(lg-up) {
            width: 25%;
        }
        @include for-size(xl-up) {
            width: 20%;
        }
        p {
            color: $lightColor;
        }
    }

    &-bio {
        width: 100%;
        @include for-size(xl-up) {
            width: 20%;
        }
    }

    &-copy {
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        background-color: $lightColor;
        padding: 15px;
        z-index: 12;
        position: relative;
        margin-top: 35px;
        @include for-size(lg-up) {
            margin-top: 45px;
        }
        @include for-size(xl-up) {
            margin-top: 167px;
        }
    }
}