
.testimonial {
    &-section {
        &-slide {
            .slick-slide {
                margin: 0 8px;
            }
        }

        position: relative;

        &::after {
            content: "";
            width: 52px;
            height: 50px;
            background-image: url(../../img/quote.svg);
            background-size: cover;
            opacity: .06;
            position: absolute;
            top: -18px;
            right: 50%;
            margin-right: -31px;
            @include for-size(md-up) {
                width: 120px;
                height: 110px;
                top: -15px;
                right: 30px;
                margin-right: 0;
            }
            @include for-size(lg-up) {
                width: 180px;
                height: 170px;
                top: -50px;
                right: 30px;
            }
            @include for-size(xl-up) {
                width: 230px;
                height: 210px;
                top: -111px;
                right: 70px;
            }
            @include for-size(xxl-up) {
                width: 310px;
                height: 270px;
                top: -160px;
                right: 140px;
            }
        }
    }
    
    &-col {
        background-color: darken($lightColor, 2.35);
        padding: 35px 23px 25px;
        @include borderRadius(5px);  
        @include for-size(md-up) {
            padding: 60px 35px 40px;
        }      
    }
}