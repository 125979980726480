
.contact {
    &-form {
        padding: 25px;
        background-color: darken($lightColor, 3.92);
        height: 100%;
        @include borderRadius(8px);
    }

    &-info {
        background-color:  darken(desaturate($main-color, 7.48), 11.57);
        height: 100%;
        @include borderRadius(8px);
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: var(--background);
            background-size: cover;
            opacity: 0.28;
            @include borderRadius(8px);
        }

        &-icon {
            width: 170px;
            height: 170px;
            padding: 22px;
            margin: 0 auto 15px;
            @include flexbox;
            justify-content: center;
            align-items: center;
            border: rgba(darken(desaturate($main-color, 9.27), 5.49), 73) 14px solid;
            background-color: darken(desaturate($main-color, 4.26), 13.33);
            @include borderRadius(50%);
            @include for-size(lg-up) {
                width: 230px;
                height: 230px;
                border-width: 19px;
            }
            svg {
                width: 142px;
                height: auto;
            }            
        }

        .position-relative {
            z-index: 12;
        }

        h4 {
            color: lighten(saturate(adjust-hue($main-color, -20), 20.85), 12.55);
        }

        a {
            &:hover {
                color: lighten(saturate(adjust-hue($main-color, -20), 20.85), 12.55);
            }
        }
    }

    &-location {
        img {
           height: auto;
        }
    }
}