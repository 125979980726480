
.banner {
    &-home {
        background-size: cover;
        background-repeat: no-repeat;
        padding: 20px 0;

        h2, h3, h4 {
            font-family: $font-sub;
            margin-bottom: 0;
        }
        h2 {
            font-weight: 500;
        }
        h3 {
            font-weight: 800;
            color: $main-color;
            font-size: 30px;
            @include for-size(md-up) {
                font-size: 44px;
            } 
        }
        h4 {
            text-transform: uppercase;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 1rem;
        }
        ul {
            margin: 0 -7px;
            li{
                width: calc(50% - 14px);
                padding: 0 7px;
                margin: 5px 7px;
                height: 63px;
                text-align: left;
                @include for-size(md-up) {
                    width: calc(33.3333% - 14px);
                }
                img {
                    width: 38px;
                    margin-right: 8px;
                }

                &.btn {
                    color: $main-color;
                    border: $lightColor 2px solid;
                    line-height: 1.1;
                    @include box-shadow(0,2px,1px,1px, rgba($darkColor, 0.1));
                    &:hover {
                        color: $lightColor;
                        border-color: $main-color;
                        img {
                            filter: invert(1) grayscale(1) brightness(3);
                        }
                    }
                }
            }
        }

        .slick {
            &-dots {
                bottom: -16px;
                margin: 0;
                display: flex !important;
                flex-wrap: wrap;
                padding: 0 35px;
                justify-content: center;
                @include for-size(lg-up) {
                    justify-content: flex-start;
                }
                li {
                    margin: 0 3px;
                    padding: 0;
                    width: auto;
                    height: auto;
                    button {
                        background-color: $lightColor;
                        border: none;
                        opacity: 0.7;
                    }
                    &.slick-active {
                        button {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}